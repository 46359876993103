/**
 * @generated SignedSource<<3d5794785de1a2d66b2f53c7c73d9018>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SidebarProjectsQuery$variables = {
  projectCursor?: string | null;
  spaceCount?: number | null;
  id: string;
};
export type SidebarProjectsQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"SidebarSpacesProjectsFragment">;
  } | null;
};
export type SidebarProjectsQuery = {
  variables: SidebarProjectsQuery$variables;
  response: SidebarProjectsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectCursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "spaceCount"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "projectCursor"
  },
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "project": true
    }
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "spaceCount"
  }
],
v7 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SidebarProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SidebarSpacesProjectsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SidebarProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "projects",
                "args": (v6/*: any*/),
                "concreteType": "SpaceConnection",
                "kind": "LinkedField",
                "name": "spaces",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SpaceEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Space",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "spaceId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilepic",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "project",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "projectType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SpaceUser",
                            "kind": "LinkedField",
                            "name": "currUser",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "spaceUserId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "accepted",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "request",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "requestType",
                                "storageKey": null
                              },
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "favorite",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "projects",
                "args": (v6/*: any*/),
                "filters": [
                  "filters"
                ],
                "handle": "connection",
                "key": "SidebarSpacesFragment_projects",
                "kind": "LinkedHandle",
                "name": "spaces"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f89585c8154fde0b52e1564b715ad78d",
    "id": null,
    "metadata": {},
    "name": "SidebarProjectsQuery",
    "operationKind": "query",
    "text": "query SidebarProjectsQuery(\n  $projectCursor: String\n  $spaceCount: Int\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...SidebarSpacesProjectsFragment\n    id\n  }\n}\n\nfragment JoinButtonFragment on Space {\n  spaceId\n  id\n  currUser {\n    id\n    spaceUserId\n    type\n    accepted\n    request\n    requestType\n  }\n}\n\nfragment SidebarSpacesProjectsFragment on User {\n  projects: spaces(first: $spaceCount, after: $projectCursor, filters: {project: true}) {\n    edges {\n      node {\n        id\n        spaceId\n        ...SpaceListItemFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n  id\n}\n\nfragment SpaceFavoriteButtonFragment on Space {\n  favorite\n  spaceId\n}\n\nfragment SpaceIconFragment on Space {\n  profilepic\n  project\n  projectType\n}\n\nfragment SpaceListItemFragment on Space {\n  name\n  spaceId\n  ...SpaceIconFragment\n  ...SpacePreviewCardWrapperFragment\n  ...JoinButtonFragment\n  ...SpaceFavoriteButtonFragment\n}\n\nfragment SpacePreviewCardWrapperFragment on Space {\n  spaceId\n}\n"
  }
};
})();

(node as any).hash = "f6026f855774dee3ccc9c77c70f885a7";

export default node;
