/**
 * @generated SignedSource<<7db5849d2d2bd2b8a533d62c2ebca1c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentsLazyChildrenQuery$variables = {
  messageId: number;
  commentCount: number;
  commentCursor?: string | null;
  reactionCount: number;
  reactionCursor?: string | null;
};
export type CommentsLazyChildrenQuery$data = {
  readonly message: {
    readonly messageId: string;
    readonly " $fragmentSpreads": FragmentRefs<"CommentsLazyChildrenFragment">;
  } | null;
};
export type CommentsLazyChildrenQuery = {
  variables: CommentsLazyChildrenQuery$variables;
  response: CommentsLazyChildrenQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentCount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentCursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "messageId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reactionCount"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reactionCursor"
},
v5 = [
  {
    "kind": "Variable",
    "name": "messageId",
    "variableName": "messageId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messageId",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "commentCursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "commentCount"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdBy",
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "reactionCursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "reactionCount"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endCursor",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasNextPage",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    (v14/*: any*/),
    (v15/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v18 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 0
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentsLazyChildrenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Message",
        "kind": "LinkedField",
        "name": "message",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CommentsLazyChildrenFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "CommentsLazyChildrenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Message",
        "kind": "LinkedField",
        "name": "message",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "MessageConnection",
            "kind": "LinkedField",
            "name": "children",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MessageEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Message",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstname",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastname",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilepic",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "userId",
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "edited",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deleted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pinned",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": (v10/*: any*/),
                        "concreteType": "ReactionConnection",
                        "kind": "LinkedField",
                        "name": "reactions",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReactionEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Reaction",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "emoji",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  },
                                  (v11/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "spaceId",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "count",
                                    "storageKey": null
                                  },
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "reactionId",
                                    "storageKey": null
                                  },
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v16/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v10/*: any*/),
                        "filters": null,
                        "handle": "connection",
                        "key": "MessageReactionsFragment_reactions",
                        "kind": "LinkedHandle",
                        "name": "reactions"
                      },
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "childrenCount",
                        "storageKey": null
                      },
                      {
                        "alias": "empty_children",
                        "args": (v18/*: any*/),
                        "concreteType": "MessageConnection",
                        "kind": "LinkedField",
                        "name": "children",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MessageEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Message",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "children(first:0)"
                      },
                      {
                        "alias": "empty_children",
                        "args": (v18/*: any*/),
                        "filters": null,
                        "handle": "connection",
                        "key": "CommentsAddOn_empty_children",
                        "kind": "LinkedHandle",
                        "name": "children"
                      },
                      (v17/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "CommentsLazyChildren_children",
            "kind": "LinkedHandle",
            "name": "children"
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f2d4c1a0c2a80574323cfeb39d53d037",
    "id": null,
    "metadata": {},
    "name": "CommentsLazyChildrenQuery",
    "operationKind": "query",
    "text": "query CommentsLazyChildrenQuery(\n  $messageId: Int!\n  $commentCount: Int!\n  $commentCursor: String\n  $reactionCount: Int!\n  $reactionCursor: String\n) {\n  message(messageId: $messageId) {\n    messageId\n    ...CommentsLazyChildrenFragment\n    id\n  }\n}\n\nfragment CommentsAddOnFragment on Message {\n  messageId\n  id\n  postId\n  childrenCount\n  empty_children: children(first: 0) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n  }\n  createdBy\n  deleted\n}\n\nfragment CommentsLazyChildrenFragment on Message {\n  children(first: $commentCount, after: $commentCursor) {\n    edges {\n      node {\n        messageId\n        ...MessageCard_fragment\n        ...CommentsAddOnFragment\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment MessageCard_fragment on Message {\n  id\n  messageId\n  user {\n    firstname\n    lastname\n    profilepic\n    userId\n    ...UserPreviewCardWrapperFragment\n    ...UserIconFragment\n    id\n  }\n  body\n  createdAt\n  edited\n  deleted\n  pinned\n  createdBy\n  ...MessageReactionsFragment\n  ...CommentsAddOnFragment\n}\n\nfragment MessageReactionsFragment on Message {\n  reactions(first: $reactionCount, after: $reactionCursor) {\n    edges {\n      node {\n        id\n        ...ReactionChipFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  messageId\n  id\n}\n\nfragment ReactionChipFragment on Reaction {\n  emoji\n  name\n  postId\n  messageId\n  spaceId\n  count\n  createdBy\n  reactionId\n}\n\nfragment UserIconFragment on User {\n  userId\n  firstname\n  lastname\n  profilepic\n}\n\nfragment UserPreviewCardWrapperFragment on User {\n  userId\n}\n"
  }
};
})();

(node as any).hash = "9e30d94789847f983131c1569fdd5b34";

export default node;
