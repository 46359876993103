/**
 * @generated SignedSource<<a5e41f5fe310942b80a34eb18fa5ccc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DMCreatorInitialUsersQuery$variables = {
  userIds: ReadonlyArray<string>;
};
export type DMCreatorInitialUsersQuery$data = {
  readonly usersByIds: ReadonlyArray<{
    readonly firstname: string | null;
    readonly lastname: string | null;
    readonly userId: number;
  } | null> | null;
};
export type DMCreatorInitialUsersQuery = {
  variables: DMCreatorInitialUsersQuery$variables;
  response: DMCreatorInitialUsersQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userIds",
    "variableName": "userIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DMCreatorInitialUsersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "usersByIds",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DMCreatorInitialUsersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "usersByIds",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1f70919775cad74720d7958f3dd01911",
    "id": null,
    "metadata": {},
    "name": "DMCreatorInitialUsersQuery",
    "operationKind": "query",
    "text": "query DMCreatorInitialUsersQuery(\n  $userIds: [ID!]!\n) {\n  usersByIds(userIds: $userIds) {\n    firstname\n    lastname\n    userId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ec037e9d034c8f80bc324d13125279cd";

export default node;
