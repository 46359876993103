/**
 * @generated SignedSource<<3ab7a21a80a6347a0b6ee9f8acc02a1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateConnectionInput = {
  user1Id: number;
  user2Id: number;
  follower?: boolean | null;
};
export type CreateConnectionMutation$variables = {
  input: CreateConnectionInput;
};
export type CreateConnectionMutation$data = {
  readonly createConnection: {
    readonly userId: number;
    readonly id: string;
    readonly connection: {
      readonly id: string;
      readonly connectionId: number | null;
      readonly user1Id: number | null;
      readonly user2Id: number | null;
      readonly accepted: boolean | null;
    } | null;
  } | null;
};
export type CreateConnectionMutation = {
  variables: CreateConnectionMutation$variables;
  response: CreateConnectionMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "createConnection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Connection",
        "kind": "LinkedField",
        "name": "connection",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "connectionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "user1Id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "user2Id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accepted",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateConnectionMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateConnectionMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "85c8eb7aac42b7b496b878766f572256",
    "id": null,
    "metadata": {},
    "name": "CreateConnectionMutation",
    "operationKind": "mutation",
    "text": "mutation CreateConnectionMutation(\n  $input: CreateConnectionInput!\n) {\n  createConnection(input: $input) {\n    userId\n    id\n    connection {\n      id\n      connectionId\n      user1Id\n      user2Id\n      accepted\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ddf2529134ed54bcb8020eb8226f4add";

export default node;
