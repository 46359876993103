/**
 * @generated SignedSource<<3c35c41cd2ee1d2d614a0cb96001dd2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JoinSpaceInput = {
  spaceId: number;
  type: string;
  requestType?: string | null;
};
export type JoinButtonJoinMutation$variables = {
  input: JoinSpaceInput;
};
export type JoinButtonJoinMutation$data = {
  readonly joinSpace: {
    readonly userEdge: {
      readonly node: {
        readonly spaceUser: {
          readonly id: string;
          readonly spaceUserId: string | null;
          readonly type: string | null;
          readonly accepted: boolean | null;
          readonly request: boolean | null;
          readonly requestType: string | null;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"UserListItemFragment">;
      } | null;
    } | null;
  } | null;
};
export type JoinButtonJoinMutation = {
  variables: JoinButtonJoinMutation$variables;
  response: JoinButtonJoinMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "spaceUserId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accepted",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "request",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JoinButtonJoinMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JoinSpace",
        "kind": "LinkedField",
        "name": "joinSpace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "userEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserListItemFragment"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SpaceUser",
                    "kind": "LinkedField",
                    "name": "spaceUser",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JoinButtonJoinMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JoinSpace",
        "kind": "LinkedField",
        "name": "joinSpace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "userEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profilepic",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Connection",
                    "kind": "LinkedField",
                    "name": "connection",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "connectionId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "user1Id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "user2Id",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SpaceUser",
                    "kind": "LinkedField",
                    "name": "spaceUser",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "12ef263dce177531e605d7fe7b98d6c1",
    "id": null,
    "metadata": {},
    "name": "JoinButtonJoinMutation",
    "operationKind": "mutation",
    "text": "mutation JoinButtonJoinMutation(\n  $input: JoinSpaceInput!\n) {\n  joinSpace(input: $input) {\n    userEdge {\n      node {\n        ...UserListItemFragment\n        spaceUser {\n          id\n          spaceUserId\n          type\n          accepted\n          request\n          requestType\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment ConnectButtonFragment_connection on User {\n  userId\n  connection {\n    id\n    connectionId\n    user1Id\n    user2Id\n    accepted\n  }\n}\n\nfragment UserIconFragment on User {\n  userId\n  firstname\n  lastname\n  profilepic\n}\n\nfragment UserListItemFragment on User {\n  ...UserIconFragment\n  ...UserPreviewCardWrapperFragment\n  ...ConnectButtonFragment_connection\n  firstname\n  lastname\n  email\n  userId\n  spaceUser {\n    id\n    spaceUserId\n    type\n    requestType\n    accepted\n    request\n  }\n}\n\nfragment UserPreviewCardWrapperFragment on User {\n  userId\n}\n"
  }
};
})();

(node as any).hash = "1eb40449bc796b531e12b11199826b4f";

export default node;
