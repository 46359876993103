/**
 * @generated SignedSource<<7a513742c2519a30d0a211f5c12e5e1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateRoomInput = {
  spaceId?: number | null;
  userIds?: ReadonlyArray<string | null> | null;
  name?: string | null;
  type?: string | null;
  dm?: boolean | null;
  visibility?: string | null;
  index?: number | null;
  clientId?: string | null;
};
export type CreateChannelMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateRoomInput;
};
export type CreateChannelMutation$data = {
  readonly createRoom: {
    readonly roomEdge: {
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly spaceId: number | null;
        readonly roomId: number | null;
        readonly type: string | null;
        readonly currUser: {
          readonly unread: boolean | null;
          readonly unreadNum: number | null;
        } | null;
        readonly users: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly roomUserId: number | null;
              readonly user: {
                readonly userId: number;
                readonly firstname: string | null;
                readonly lastname: string | null;
                readonly profilepic: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"SpaceChannelListItemFragment" | "ChannelListItem">;
      } | null;
    } | null;
  } | null;
};
export type CreateChannelMutation = {
  variables: CreateChannelMutation$variables;
  response: CreateChannelMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "spaceId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roomId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unread",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unreadNum",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roomUserId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilepic",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateChannelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RoomEdgeEdge",
        "kind": "LinkedField",
        "name": "createRoom",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoomEdge",
            "kind": "LinkedField",
            "name": "roomEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Room",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SpaceChannelListItemFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ChannelListItem"
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoomUser",
                    "kind": "LinkedField",
                    "name": "currUser",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "RoomUserConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoomUserEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RoomUser",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v14/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "users(first:20)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateChannelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RoomEdgeEdge",
        "kind": "LinkedField",
        "name": "createRoom",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoomEdge",
            "kind": "LinkedField",
            "name": "roomEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Room",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numUsers",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "visibility",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "otherUser",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoomUser",
                    "kind": "LinkedField",
                    "name": "currUser",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v10/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "RoomUserConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoomUserEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RoomUser",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v14/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "users(first:20)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "roomEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "16b755255e89ca559bb138d0d4677e06",
    "id": null,
    "metadata": {},
    "name": "CreateChannelMutation",
    "operationKind": "mutation",
    "text": "mutation CreateChannelMutation(\n  $input: CreateRoomInput!\n) {\n  createRoom(input: $input) {\n    roomEdge {\n      node {\n        ...SpaceChannelListItemFragment\n        ...ChannelListItem\n        id\n        name\n        spaceId\n        roomId\n        type\n        currUser {\n          unread\n          unreadNum\n          id\n        }\n        users(first: 20) {\n          edges {\n            node {\n              roomUserId\n              user {\n                userId\n                firstname\n                lastname\n                profilepic\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ChannelListItem on Room {\n  name\n  roomId\n  type\n  dm\n  spaceId\n  ...RoomIcon\n  ...UnreadChipFragment\n  currUser {\n    id\n    roomUserId\n    unread\n    unreadNum\n  }\n}\n\nfragment RoomIcon on Room {\n  type\n  dm\n  spaceId\n  otherUser {\n    userId\n    firstname\n    lastname\n    id\n  }\n  profilepic\n}\n\nfragment RoomSettings_fragment on Room {\n  name\n  id\n  roomId\n  description\n  type\n  visibility\n  currUser {\n    id\n    roomUserId\n    unread\n    unreadNum\n  }\n}\n\nfragment SpaceChannelListItemFragment on Room {\n  id\n  name\n  roomId\n  type\n  spaceId\n  numUsers\n  visibility\n  dm\n  ...RoomIcon\n  ...RoomSettings_fragment\n  ...UnreadChipFragment\n  currUser {\n    id\n    roomUserId\n    unread\n    unreadNum\n  }\n}\n\nfragment UnreadChipFragment on Room {\n  currUser {\n    unreadNum\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f5aaa1fec24809c67fa3a7dcddeabb58";

export default node;
