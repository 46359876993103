/**
 * @generated SignedSource<<4177f80513015e8f05da3ced3e6c515d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SpaceParamsInfoFragment$data = {
  readonly type: string | null;
  readonly project: boolean | null;
  readonly accessType: string | null;
  readonly address: string | null;
  readonly " $fragmentType": "SpaceParamsInfoFragment";
};
export type SpaceParamsInfoFragment$key = {
  readonly " $data"?: SpaceParamsInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpaceParamsInfoFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpaceParamsInfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "project",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    }
  ],
  "type": "Space",
  "abstractKey": null
};

(node as any).hash = "f8a272d966d3c5496f13fac149c200e7";

export default node;
