/**
 * @generated SignedSource<<f90cba65e0b9529b2926807fee119c52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DisplayOptionsFragment$data = {
  readonly darkMode: boolean | null;
  readonly productivityView: boolean | null;
  readonly " $fragmentType": "DisplayOptionsFragment";
};
export type DisplayOptionsFragment$key = {
  readonly " $data"?: DisplayOptionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DisplayOptionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DisplayOptionsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "darkMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productivityView",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "8954dba58324ed1d3dde7b6a444774f5";

export default node;
