/**
 * @generated SignedSource<<e9dc04b57830f770461d09c998cb928e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessageCard_fragment$data = {
  readonly __id: string;
  readonly id: string;
  readonly messageId: string;
  readonly user: {
    readonly firstname: string | null;
    readonly lastname: string | null;
    readonly profilepic: string | null;
    readonly userId: number;
    readonly " $fragmentSpreads": FragmentRefs<"UserPreviewCardWrapperFragment" | "UserIconFragment">;
  } | null;
  readonly body: string | null;
  readonly createdAt: string | null;
  readonly edited: boolean | null;
  readonly deleted: boolean | null;
  readonly pinned: boolean | null;
  readonly createdBy: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"MessageReactionsFragment" | "CommentsAddOnFragment">;
  readonly " $fragmentType": "MessageCard_fragment";
};
export type MessageCard_fragment$key = {
  readonly " $data"?: MessageCard_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageCard_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageCard_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messageId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilepic",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserPreviewCardWrapperFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserIconFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "edited",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pinned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdBy",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MessageReactionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentsAddOnFragment"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__id",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Message",
  "abstractKey": null
};

(node as any).hash = "2815a00c2c6dfd1997ef77bb1676287f";

export default node;
