/**
 * @generated SignedSource<<f9fa3bb2bff68d6b175d46cf1f78816e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateSpaceUserInput = {
  spaceUserId: number;
  type?: string | null;
  accepted?: boolean | null;
  request?: boolean | null;
  requestType?: string | null;
};
export type JoinButtonUpdateMutation$variables = {
  input: UpdateSpaceUserInput;
};
export type JoinButtonUpdateMutation$data = {
  readonly updateSpaceUser: {
    readonly id: string;
    readonly spaceUserId: string | null;
    readonly type: string | null;
    readonly accepted: boolean | null;
    readonly request: boolean | null;
    readonly requestType: string | null;
  } | null;
};
export type JoinButtonUpdateMutation = {
  variables: JoinButtonUpdateMutation$variables;
  response: JoinButtonUpdateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SpaceUser",
    "kind": "LinkedField",
    "name": "updateSpaceUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "spaceUserId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accepted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "request",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requestType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JoinButtonUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JoinButtonUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1c2ead2d22dde0fd8b911122a49cadfd",
    "id": null,
    "metadata": {},
    "name": "JoinButtonUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation JoinButtonUpdateMutation(\n  $input: UpdateSpaceUserInput!\n) {\n  updateSpaceUser(input: $input) {\n    id\n    spaceUserId\n    type\n    accepted\n    request\n    requestType\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6044f135b0e02ea941295b11337f47c";

export default node;
