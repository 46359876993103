/**
 * @generated SignedSource<<09d11694c4a5c538179f080c482bcdb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SpaceIconFragment$data = {
  readonly profilepic: string | null;
  readonly project: boolean | null;
  readonly projectType: string | null;
  readonly " $fragmentType": "SpaceIconFragment";
};
export type SpaceIconFragment$key = {
  readonly " $data"?: SpaceIconFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpaceIconFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpaceIconFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilepic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "project",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectType",
      "storageKey": null
    }
  ],
  "type": "Space",
  "abstractKey": null
};

(node as any).hash = "96b308ab482185d96ca07089fab5b849";

export default node;
