/**
 * @generated SignedSource<<dd13749282bac9371c3a646347c7d802>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChannelListItem$data = {
  readonly name: string | null;
  readonly roomId: number | null;
  readonly type: string | null;
  readonly dm: boolean | null;
  readonly spaceId: number | null;
  readonly currUser: {
    readonly id: string;
    readonly roomUserId: number | null;
    readonly unread: boolean | null;
    readonly unreadNum: number | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"RoomIcon" | "UnreadChipFragment">;
  readonly " $fragmentType": "ChannelListItem";
};
export type ChannelListItem$key = {
  readonly " $data"?: ChannelListItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChannelListItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChannelListItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roomId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spaceId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RoomIcon"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UnreadChipFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoomUser",
      "kind": "LinkedField",
      "name": "currUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roomUserId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unread",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadNum",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Room",
  "abstractKey": null
};

(node as any).hash = "11ad19a65ac0b06b170b67b1f7c13b9a";

export default node;
