/**
 * @generated SignedSource<<fed7c201ba8a84d9c5155d2b31b46c80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RoomUsersListItem$data = {
  readonly firstname: string | null;
  readonly lastname: string | null;
  readonly active: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"UserPreviewCardWrapperFragment" | "UserIconFragment">;
  readonly " $fragmentType": "RoomUsersListItem";
};
export type RoomUsersListItem$key = {
  readonly " $data"?: RoomUsersListItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"RoomUsersListItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoomUsersListItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserPreviewCardWrapperFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserIconFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3ccb1ed1caa47e50dcc97d85c1482eb2";

export default node;
