/**
 * @generated SignedSource<<89eada486feece09e56de8b7406d2aec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessageWindowPanelFragment_user$data = {
  readonly userId: number;
  readonly firstname: string | null;
  readonly lastname: string | null;
  readonly profilepic: string | null;
  readonly " $fragmentType": "MessageWindowPanelFragment_user";
};
export type MessageWindowPanelFragment_user$key = {
  readonly " $data"?: MessageWindowPanelFragment_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageWindowPanelFragment_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageWindowPanelFragment_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilepic",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "bccc232d0756b62a8bcba7f7358cf43f";

export default node;
