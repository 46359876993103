/**
 * @generated SignedSource<<44fc6075fdf63db7b9965c8dfc00231e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type JoinButtonQuery$variables = {};
export type JoinButtonQuery$data = {
  readonly me: {
    readonly userId: number;
  } | null;
};
export type JoinButtonQuery = {
  variables: JoinButtonQuery$variables;
  response: JoinButtonQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "JoinButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "JoinButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aa2a59d27e4798436437b282cd647cb3",
    "id": null,
    "metadata": {},
    "name": "JoinButtonQuery",
    "operationKind": "query",
    "text": "query JoinButtonQuery {\n  me {\n    userId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "935028b4235257902634cbf7aa94489f";

export default node;
