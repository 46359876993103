/**
 * @generated SignedSource<<abb8504f9985322073b188a659ca264c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserPreviewCardQuery$variables = {
  id: string;
};
export type UserPreviewCardQuery$data = {
  readonly user: {
    readonly userId: number;
    readonly profilepic: string | null;
    readonly bannerpic: string | null;
    readonly firstname: string | null;
    readonly lastname: string | null;
    readonly bio: string | null;
    readonly connection: {
      readonly user1Id: number | null;
      readonly user2Id: number | null;
      readonly accepted: boolean | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ConnectButtonFragment_connection" | "UserMessageButtonFragment_user" | "UserNameFragment" | "UserIconFragment">;
  } | null;
  readonly me: {
    readonly userId: number;
    readonly " $fragmentSpreads": FragmentRefs<"UserMessageButtonFragment_me">;
  } | null;
};
export type UserPreviewCardQuery = {
  variables: UserPreviewCardQuery$variables;
  response: UserPreviewCardQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilepic",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bannerpic",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bio",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user1Id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user2Id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accepted",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserPreviewCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Connection",
            "kind": "LinkedField",
            "name": "connection",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConnectButtonFragment_connection"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserMessageButtonFragment_user"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserNameFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserIconFragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserMessageButtonFragment_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserPreviewCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Connection",
            "kind": "LinkedField",
            "name": "connection",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "connectionId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "540b46ea081503068ded0c59a0daa307",
    "id": null,
    "metadata": {},
    "name": "UserPreviewCardQuery",
    "operationKind": "query",
    "text": "query UserPreviewCardQuery(\n  $id: ID!\n) {\n  user(userId: $id) {\n    userId\n    profilepic\n    bannerpic\n    firstname\n    lastname\n    bio\n    connection {\n      user1Id\n      user2Id\n      accepted\n      id\n    }\n    ...ConnectButtonFragment_connection\n    ...UserMessageButtonFragment_user\n    ...UserNameFragment\n    ...UserIconFragment\n    id\n  }\n  me {\n    userId\n    ...UserMessageButtonFragment_me\n    id\n  }\n}\n\nfragment ConnectButtonFragment_connection on User {\n  userId\n  connection {\n    id\n    connectionId\n    user1Id\n    user2Id\n    accepted\n  }\n}\n\nfragment UserIconFragment on User {\n  userId\n  firstname\n  lastname\n  profilepic\n}\n\nfragment UserMessageButtonFragment_me on User {\n  userId\n}\n\nfragment UserMessageButtonFragment_user on User {\n  userId\n}\n\nfragment UserNameFragment on User {\n  firstname\n  lastname\n  userId\n}\n"
  }
};
})();

(node as any).hash = "75f7e458cf6ad80e6ab3c4c5578fa628";

export default node;
