/**
 * @generated SignedSource<<212d9adddbe7845ca73fe6e51124fa64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserDmSubInput = {
  userId?: number | null;
  clientId?: string | null;
};
export type UserDMSubscription$variables = {
  input: UserDmSubInput;
};
export type UserDMSubscription$data = {
  readonly directMessages: {
    readonly roomEdge: {
      readonly node: {
        readonly currUser: {
          readonly unread: boolean | null;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"ChannelListItem" | "RoomSettings_fragment">;
      } | null;
    } | null;
    readonly room: {
      readonly id: string;
      readonly name: string | null;
      readonly description: string | null;
      readonly type: string | null;
      readonly visibility: string | null;
    } | null;
    readonly deletedRoomId: string | null;
    readonly clientId: string | null;
  } | null;
};
export type UserDMSubscription = {
  variables: UserDMSubscription$variables;
  response: UserDMSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unread",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Room",
  "kind": "LinkedField",
  "name": "room",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedRoomId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserDMSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChannelSubReply",
        "kind": "LinkedField",
        "name": "directMessages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoomEdge",
            "kind": "LinkedField",
            "name": "roomEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Room",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ChannelListItem"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RoomSettings_fragment"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoomUser",
                    "kind": "LinkedField",
                    "name": "currUser",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserDMSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChannelSubReply",
        "kind": "LinkedField",
        "name": "directMessages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoomEdge",
            "kind": "LinkedField",
            "name": "roomEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Room",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roomId",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "spaceId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "otherUser",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstname",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastname",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profilepic",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoomUser",
                    "kind": "LinkedField",
                    "name": "currUser",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unreadNum",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "roomUserId",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f1e44b5527c21e90938fc1add02bac08",
    "id": null,
    "metadata": {},
    "name": "UserDMSubscription",
    "operationKind": "subscription",
    "text": "subscription UserDMSubscription(\n  $input: UserDmSubInput!\n) {\n  directMessages(input: $input) {\n    roomEdge {\n      node {\n        ...ChannelListItem\n        ...RoomSettings_fragment\n        currUser {\n          unread\n          id\n        }\n        id\n      }\n    }\n    room {\n      id\n      name\n      description\n      type\n      visibility\n    }\n    deletedRoomId\n    clientId\n  }\n}\n\nfragment ChannelListItem on Room {\n  name\n  roomId\n  type\n  dm\n  spaceId\n  ...RoomIcon\n  ...UnreadChipFragment\n  currUser {\n    id\n    roomUserId\n    unread\n    unreadNum\n  }\n}\n\nfragment RoomIcon on Room {\n  type\n  dm\n  spaceId\n  otherUser {\n    userId\n    firstname\n    lastname\n    id\n  }\n  profilepic\n}\n\nfragment RoomSettings_fragment on Room {\n  name\n  id\n  roomId\n  description\n  type\n  visibility\n  currUser {\n    id\n    roomUserId\n    unread\n    unreadNum\n  }\n}\n\nfragment UnreadChipFragment on Room {\n  currUser {\n    unreadNum\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a180e601a7ca289deaa1e5ab2700e1c7";

export default node;
