/**
 * @generated SignedSource<<9c1749eb2681581bb5f4d18c847e1daf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AutosearchResultsQuery$variables = {
  query: string;
  groupNum: number;
  groupCursor?: string | null;
};
export type AutosearchResultsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AutosearchResults_groups">;
};
export type AutosearchResultsQuery = {
  variables: AutosearchResultsQuery$variables;
  response: AutosearchResultsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupCursor"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupNum"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "groupCursor"
  },
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "excludeChildren",
        "value": true
      },
      {
        "kind": "Literal",
        "name": "project",
        "value": false
      },
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "groupNum"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AutosearchResultsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AutosearchResults_groups"
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AutosearchResultsQuery",
    "selections": [
      {
        "alias": "searchGroups",
        "args": (v3/*: any*/),
        "concreteType": "SpaceConnection",
        "kind": "LinkedField",
        "name": "spaces",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SpaceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Space",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "spaceId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profilepic",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "project",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectType",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SpaceUser",
                    "kind": "LinkedField",
                    "name": "currUser",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "spaceUserId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accepted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "request",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "requestType",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "favorite",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "searchGroups",
        "args": (v3/*: any*/),
        "filters": [
          "filters"
        ],
        "handle": "connection",
        "key": "AutosearchResults_searchGroups",
        "kind": "LinkedHandle",
        "name": "spaces"
      }
    ]
  },
  "params": {
    "cacheID": "93049d0b7e3dbe566c7f09fb78678db8",
    "id": null,
    "metadata": {},
    "name": "AutosearchResultsQuery",
    "operationKind": "query",
    "text": "query AutosearchResultsQuery(\n  $query: String!\n  $groupNum: Int!\n  $groupCursor: String\n) {\n  ...AutosearchResults_groups\n}\n\nfragment AutosearchResults_groups on RootQueryType {\n  searchGroups: spaces(first: $groupNum, after: $groupCursor, filters: {query: $query, excludeChildren: true, project: false}) {\n    edges {\n      node {\n        spaceId\n        ...SpaceListItemFragment\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment JoinButtonFragment on Space {\n  spaceId\n  id\n  currUser {\n    id\n    spaceUserId\n    type\n    accepted\n    request\n    requestType\n  }\n}\n\nfragment SpaceFavoriteButtonFragment on Space {\n  favorite\n  spaceId\n}\n\nfragment SpaceIconFragment on Space {\n  profilepic\n  project\n  projectType\n}\n\nfragment SpaceListItemFragment on Space {\n  name\n  spaceId\n  ...SpaceIconFragment\n  ...SpacePreviewCardWrapperFragment\n  ...JoinButtonFragment\n  ...SpaceFavoriteButtonFragment\n}\n\nfragment SpacePreviewCardWrapperFragment on Space {\n  spaceId\n}\n"
  }
};
})();

(node as any).hash = "87dc9e54caa13da3b73455fd1734b8a0";

export default node;
