/**
 * @generated SignedSource<<280f6d12ae8647995ab0861ac3c94d5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserIconFragment$data = {
  readonly userId: number;
  readonly firstname: string | null;
  readonly lastname: string | null;
  readonly profilepic: string | null;
  readonly " $fragmentType": "UserIconFragment";
};
export type UserIconFragment$key = {
  readonly " $data"?: UserIconFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserIconFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserIconFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilepic",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "55efccd47e98e6b8ee1b99dfb927c063";

export default node;
