/**
 * @generated SignedSource<<97811cc05f9a76466c14e81cfa9ea0ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteConnectionInput = {
  userId: number;
};
export type DeleteConnectionMutation$variables = {
  input: DeleteConnectionInput;
};
export type DeleteConnectionMutation$data = {
  readonly deleteConnection: {
    readonly id: string;
    readonly user1Id: number | null;
    readonly user2Id: number | null;
    readonly accepted: boolean | null;
    readonly connectionId: number | null;
  } | null;
};
export type DeleteConnectionMutation = {
  variables: DeleteConnectionMutation$variables;
  response: DeleteConnectionMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Connection",
    "kind": "LinkedField",
    "name": "deleteConnection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user1Id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user2Id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accepted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "connectionId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteConnectionMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteConnectionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c927ff06e63d705082ad987dc72ea1ac",
    "id": null,
    "metadata": {},
    "name": "DeleteConnectionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteConnectionMutation(\n  $input: DeleteConnectionInput!\n) {\n  deleteConnection(input: $input) {\n    id\n    user1Id\n    user2Id\n    accepted\n    connectionId\n  }\n}\n"
  }
};
})();

(node as any).hash = "71eea9617000f184f720412344ade520";

export default node;
