/**
 * @generated SignedSource<<ab5a2e2b994802c8ecb2f1d23eac3991>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SpacePreviewCardQuery$variables = {
  spaceId: number;
};
export type SpacePreviewCardQuery$data = {
  readonly space: {
    readonly name: string;
    readonly description: string | null;
    readonly type: string | null;
    readonly accessType: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"SpaceIconFragment" | "JoinButtonFragment" | "SpaceMessageButtonFragment" | "SpaceParamsInfoFragment">;
  } | null;
};
export type SpacePreviewCardQuery = {
  variables: SpacePreviewCardQuery$variables;
  response: SpacePreviewCardQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "spaceId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "spaceId",
    "variableName": "spaceId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacePreviewCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Space",
        "kind": "LinkedField",
        "name": "space",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SpaceIconFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JoinButtonFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SpaceMessageButtonFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SpaceParamsInfoFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SpacePreviewCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Space",
        "kind": "LinkedField",
        "name": "space",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profilepic",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "project",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "spaceId",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SpaceUser",
            "kind": "LinkedField",
            "name": "currUser",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spaceUserId",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accepted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "request",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requestType",
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc3ac116248ccb5e9f9d6013495b029a",
    "id": null,
    "metadata": {},
    "name": "SpacePreviewCardQuery",
    "operationKind": "query",
    "text": "query SpacePreviewCardQuery(\n  $spaceId: Int!\n) {\n  space(spaceId: $spaceId) {\n    name\n    description\n    type\n    accessType\n    ...SpaceIconFragment\n    ...JoinButtonFragment\n    ...SpaceMessageButtonFragment\n    ...SpaceParamsInfoFragment\n    id\n  }\n}\n\nfragment JoinButtonFragment on Space {\n  spaceId\n  id\n  currUser {\n    id\n    spaceUserId\n    type\n    accepted\n    request\n    requestType\n  }\n}\n\nfragment SpaceIconFragment on Space {\n  profilepic\n  project\n  projectType\n}\n\nfragment SpaceMessageButtonFragment on Space {\n  spaceId\n  currUser {\n    spaceUserId\n    id\n  }\n}\n\nfragment SpaceParamsInfoFragment on Space {\n  type\n  project\n  accessType\n  address\n}\n"
  }
};
})();

(node as any).hash = "910ef9d8e04fa504e082dc356a3a3c6e";

export default node;
