/**
 * @generated SignedSource<<093aa7a728b00bd01be8661e547eac01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JoinButtonFragment$data = {
  readonly spaceId: number;
  readonly id: string;
  readonly currUser: {
    readonly id: string;
    readonly spaceUserId: string | null;
    readonly __id: string;
    readonly type: string | null;
    readonly accepted: boolean | null;
    readonly request: boolean | null;
    readonly requestType: string | null;
  };
  readonly " $fragmentType": "JoinButtonFragment";
};
export type JoinButtonFragment$key = {
  readonly " $data"?: JoinButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JoinButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JoinButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spaceId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SpaceUser",
      "kind": "LinkedField",
      "name": "currUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "spaceUserId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accepted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "request",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requestType",
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Space",
  "abstractKey": null
};
})();

(node as any).hash = "c4b7853eb3b0b0859accd3dadf1d265b";

export default node;
