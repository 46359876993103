/**
 * @generated SignedSource<<c77d94f609f34c3b4c808a3a879858da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SpacePreviewCardWrapperFragment$data = {
  readonly spaceId: number;
  readonly " $fragmentType": "SpacePreviewCardWrapperFragment";
};
export type SpacePreviewCardWrapperFragment$key = {
  readonly " $data"?: SpacePreviewCardWrapperFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpacePreviewCardWrapperFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpacePreviewCardWrapperFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spaceId",
      "storageKey": null
    }
  ],
  "type": "Space",
  "abstractKey": null
};

(node as any).hash = "57630f6e1a4b18b177702bb9c6114252";

export default node;
