/**
 * @generated SignedSource<<80fa6233326b1b0c26aa961e0a401e3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReactionChipFragment$data = {
  readonly emoji: string | null;
  readonly name: string | null;
  readonly postId: number | null;
  readonly messageId: number | null;
  readonly spaceId: number | null;
  readonly count: number | null;
  readonly createdBy: number | null;
  readonly reactionId: number | null;
  readonly " $fragmentType": "ReactionChipFragment";
};
export type ReactionChipFragment$key = {
  readonly " $data"?: ReactionChipFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReactionChipFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReactionChipFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messageId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spaceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reactionId",
      "storageKey": null
    }
  ],
  "type": "Reaction",
  "abstractKey": null
};

(node as any).hash = "dcb22becb03eec70deda8cf8076cf29a";

export default node;
