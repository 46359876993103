/**
 * @generated SignedSource<<6f0538251d5cbdfe6bea90c9239a919d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PostInput = {
  postId?: number | null;
  title?: string | null;
  delta?: any | null;
  body?: string | null;
  completed?: boolean | null;
  bannerpic?: string | null;
  icon?: string | null;
  color?: string | null;
  type: string;
  priority?: number | null;
  dueDate?: any | null;
  startDate?: any | null;
  endDate?: any | null;
  latitude?: any | null;
  longitude?: any | null;
  spaceId?: number | null;
  address?: string | null;
  spaces?: ReadonlyArray<SpacePostInput | null> | null;
  assignees?: ReadonlyArray<number | null> | null;
  requestees?: ReadonlyArray<number | null> | null;
  invitees?: ReadonlyArray<number | null> | null;
  tags?: ReadonlyArray<string | null> | null;
  attributes?: ReadonlyArray<AttributeInput | null> | null;
  poll?: AttributeInput | null;
  attributeValues?: ReadonlyArray<AttributeValueInput | null> | null;
  attachments?: ReadonlyArray<FileInput | null> | null;
};
export type SpacePostInput = {
  spaceId: number;
  parentPostId?: number | null;
  index?: number | null;
  current?: boolean | null;
};
export type AttributeInput = {
  attributeId?: number | null;
  postId?: number | null;
  index?: number | null;
  name?: string | null;
  type?: string | null;
  options?: ReadonlyArray<Option | null> | null;
  description?: string | null;
};
export type Option = {
  option?: string | null;
  imageUrl?: string | null;
};
export type AttributeValueInput = {
  attributeId?: number | null;
  postId?: number | null;
  value?: string | null;
  listValue?: ReadonlyArray<string | null> | null;
};
export type FileInput = {
  title: string;
  link: string;
  format: string;
  type: string;
  size?: number | null;
  id?: string | null;
  postId?: number | null;
  description?: string | null;
};
export type WikiSliceUpdatePostMutation$variables = {
  input: PostInput;
};
export type WikiSliceUpdatePostMutation$data = {
  readonly updatePost: {
    readonly postEdge: {
      readonly node: {
        readonly postId: number | null;
      } | null;
    } | null;
  } | null;
};
export type WikiSliceUpdatePostMutation = {
  variables: WikiSliceUpdatePostMutation$variables;
  response: WikiSliceUpdatePostMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WikiSliceUpdatePostMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PostOutput",
        "kind": "LinkedField",
        "name": "updatePost",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PostEdge",
            "kind": "LinkedField",
            "name": "postEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Post",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WikiSliceUpdatePostMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PostOutput",
        "kind": "LinkedField",
        "name": "updatePost",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PostEdge",
            "kind": "LinkedField",
            "name": "postEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Post",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4a623e8255b29ac2323d053ee435fa74",
    "id": null,
    "metadata": {},
    "name": "WikiSliceUpdatePostMutation",
    "operationKind": "mutation",
    "text": "mutation WikiSliceUpdatePostMutation(\n  $input: PostInput!\n) {\n  updatePost(input: $input) {\n    postEdge {\n      node {\n        postId\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "886e3a42cce3a57b1133c284cf1c11b2";

export default node;
