/**
 * @generated SignedSource<<2e55395f76c45fce6b422e7d4d46f643>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddReactionInput = {
  name: string;
  emoji: string;
  postId?: number | null;
  messageId?: number | null;
  spaceId?: number | null;
};
export type AddReactionMutation$variables = {
  input: AddReactionInput;
  connections: ReadonlyArray<string>;
};
export type AddReactionMutation$data = {
  readonly addReaction: {
    readonly newReaction: {
      readonly cursor: string | null;
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"ReactionChipFragment">;
      } | null;
    } | null;
    readonly reaction: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ReactionChipFragment">;
    } | null;
  } | null;
};
export type AddReactionMutation = {
  variables: AddReactionMutation$variables;
  response: AddReactionMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ReactionChipFragment"
  }
],
v6 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "emoji",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "messageId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "spaceId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "createdBy",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "reactionId",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddReactionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AddReactionOutput",
        "kind": "LinkedField",
        "name": "addReaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReactionEdge",
            "kind": "LinkedField",
            "name": "newReaction",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Reaction",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Reaction",
            "kind": "LinkedField",
            "name": "reaction",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddReactionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AddReactionOutput",
        "kind": "LinkedField",
        "name": "addReaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReactionEdge",
            "kind": "LinkedField",
            "name": "newReaction",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Reaction",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "newReaction",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Reaction",
            "kind": "LinkedField",
            "name": "reaction",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fbc5846ef5858e3df1ddfc3b21ff696b",
    "id": null,
    "metadata": {},
    "name": "AddReactionMutation",
    "operationKind": "mutation",
    "text": "mutation AddReactionMutation(\n  $input: AddReactionInput!\n) {\n  addReaction(input: $input) {\n    newReaction {\n      cursor\n      node {\n        id\n        ...ReactionChipFragment\n      }\n    }\n    reaction {\n      id\n      ...ReactionChipFragment\n    }\n  }\n}\n\nfragment ReactionChipFragment on Reaction {\n  emoji\n  name\n  postId\n  messageId\n  spaceId\n  count\n  createdBy\n  reactionId\n}\n"
  }
};
})();

(node as any).hash = "d63fa56802104df786da8496e0e042cc";

export default node;
