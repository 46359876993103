/**
 * @generated SignedSource<<7a2b08f8c282bc0e377b7a5ac7539a01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SidebarSpacesFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"SidebarSpacesGroupsFragment" | "SidebarSpacesProjectsFragment" | "SidebarSpacesFavoritesFragment">;
  readonly " $fragmentType": "SidebarSpacesFragment";
};
export type SidebarSpacesFragment$key = {
  readonly " $data"?: SidebarSpacesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SidebarSpacesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SidebarSpacesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SidebarSpacesGroupsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SidebarSpacesProjectsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SidebarSpacesFavoritesFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f93adb5b03d06b4a871cf5ebbd7265d7";

export default node;
