/**
 * @generated SignedSource<<f43b57c56d7b2a074cd08d399ce52cd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserMessageButtonQuery$variables = {
  userIds: ReadonlyArray<string>;
};
export type UserMessageButtonQuery$data = {
  readonly roomByUsers: {
    readonly roomId: number | null;
  } | null;
};
export type UserMessageButtonQuery = {
  variables: UserMessageButtonQuery$variables;
  response: UserMessageButtonQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userIds"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "unarchive",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "userIds",
    "variableName": "userIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roomId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserMessageButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Room",
        "kind": "LinkedField",
        "name": "roomByUsers",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserMessageButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Room",
        "kind": "LinkedField",
        "name": "roomByUsers",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1526d7b6e57150003a4cb21ac480f169",
    "id": null,
    "metadata": {},
    "name": "UserMessageButtonQuery",
    "operationKind": "query",
    "text": "query UserMessageButtonQuery(\n  $userIds: [ID!]!\n) {\n  roomByUsers(userIds: $userIds, unarchive: true) {\n    roomId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9f5501cd89b8f8b08485ab99073a0d8";

export default node;
