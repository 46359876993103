/**
 * @generated SignedSource<<0fb4dd83a28028510776b269ecfc406e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserPreviewCardWrapperFragment$data = {
  readonly userId: number;
  readonly " $fragmentType": "UserPreviewCardWrapperFragment";
};
export type UserPreviewCardWrapperFragment$key = {
  readonly " $data"?: UserPreviewCardWrapperFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserPreviewCardWrapperFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserPreviewCardWrapperFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "bfe29ea0a887be35635c34d326e89c6b";

export default node;
