/**
 * @generated SignedSource<<a818b9919fffa7d5a7262f181ad3abad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RoomUsersList_active_users$data = {
  readonly activeUsers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly user: {
          readonly userId: number;
          readonly " $fragmentSpreads": FragmentRefs<"RoomUsersListItem">;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "RoomUsersList_active_users";
};
export type RoomUsersList_active_users$key = {
  readonly " $data"?: RoomUsersList_active_users$data;
  readonly " $fragmentSpreads": FragmentRefs<"RoomUsersList_active_users">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "activeUsers"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userCount"
    },
    {
      "kind": "RootArgument",
      "name": "userCursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "userCount",
        "cursor": "userCursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "userCount",
          "cursor": "userCursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ActiveRoomUsersListPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "RoomUsersList_active_users",
  "selections": [
    {
      "alias": "activeUsers",
      "args": null,
      "concreteType": "RoomUserConnection",
      "kind": "LinkedField",
      "name": "__RoomUsersList_activeUsers_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoomUserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoomUser",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "userId",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "RoomUsersListItem"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Room",
  "abstractKey": null
};
})();

(node as any).hash = "eb927ae95631465a4663ac2c7a396c95";

export default node;
