/**
 * @generated SignedSource<<c96c02f9fd5c6f41aee6a5d1ae50a34b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangePrefsInput = {
  darkMode?: boolean | null;
  productivityView?: boolean | null;
};
export type UpdateUserPrefsMutation$variables = {
  input: ChangePrefsInput;
};
export type UpdateUserPrefsMutation$data = {
  readonly changePreferences: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"DisplayOptionsFragment">;
  } | null;
};
export type UpdateUserPrefsMutation = {
  variables: UpdateUserPrefsMutation$variables;
  response: UpdateUserPrefsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserPrefsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "changePreferences",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DisplayOptionsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateUserPrefsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "changePreferences",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "darkMode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productivityView",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e10fd64dc705abca8bfdd63b70f51697",
    "id": null,
    "metadata": {},
    "name": "UpdateUserPrefsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserPrefsMutation(\n  $input: ChangePrefsInput!\n) {\n  changePreferences(input: $input) {\n    id\n    ...DisplayOptionsFragment\n  }\n}\n\nfragment DisplayOptionsFragment on User {\n  darkMode\n  productivityView\n}\n"
  }
};
})();

(node as any).hash = "64d59e6e020b73063daebbfa18f8c8da";

export default node;
