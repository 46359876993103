/**
 * @generated SignedSource<<76fd7d9e230ac2fc6fe4e6844abf5c74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectButtonFragment_connection$data = {
  readonly userId: number;
  readonly connection: {
    readonly id: string;
    readonly connectionId: number | null;
    readonly user1Id: number | null;
    readonly user2Id: number | null;
    readonly accepted: boolean | null;
  } | null;
  readonly " $fragmentType": "ConnectButtonFragment_connection";
};
export type ConnectButtonFragment_connection$key = {
  readonly " $data"?: ConnectButtonFragment_connection$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectButtonFragment_connection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConnectButtonFragment_connection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Connection",
      "kind": "LinkedField",
      "name": "connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "connectionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "user1Id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "user2Id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accepted",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "c0e637e821f83d3262d8f165e0f98433";

export default node;
