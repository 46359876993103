/**
 * @generated SignedSource<<5797f024cfc6bcb3257ac7617fbfe12d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateMessageInput = {
  messageId: string;
  body: string;
};
export type MessageCardUpdateMutation$variables = {
  input: UpdateMessageInput;
};
export type MessageCardUpdateMutation$data = {
  readonly updateMessage: {
    readonly message: {
      readonly id: string;
      readonly body: string | null;
    } | null;
  } | null;
};
export type MessageCardUpdateMutation = {
  variables: MessageCardUpdateMutation$variables;
  response: MessageCardUpdateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MessageUpdateReply",
    "kind": "LinkedField",
    "name": "updateMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Message",
        "kind": "LinkedField",
        "name": "message",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageCardUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageCardUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0bb5322359a475357373e59f173eb342",
    "id": null,
    "metadata": {},
    "name": "MessageCardUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation MessageCardUpdateMutation(\n  $input: UpdateMessageInput!\n) {\n  updateMessage(input: $input) {\n    message {\n      id\n      body\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4cea2b6ad0cdf9476d5049e68fb540f3";

export default node;
