/**
 * @generated SignedSource<<5cc5cbfbb37ed6387876c1158387afaa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ServerOptions_space$data = {
  readonly id: string;
  readonly spaceId: number;
  readonly name: string;
  readonly type: string | null;
  readonly project: boolean | null;
  readonly profilepic: string | null;
  readonly numRooms: number | null;
  readonly " $fragmentType": "ServerOptions_space";
};
export type ServerOptions_space$key = {
  readonly " $data"?: ServerOptions_space$data;
  readonly " $fragmentSpreads": FragmentRefs<"ServerOptions_space">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ServerOptions_space",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spaceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "project",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilepic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numRooms",
      "storageKey": null
    }
  ],
  "type": "Space",
  "abstractKey": null
};

(node as any).hash = "d477aa99f827b26198848eb607c95e38";

export default node;
