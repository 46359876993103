export const baseURL = process.env.REACT_APP_API_URL

export const AWAITING_SIGN_IN = 'AWAITING_SIGN_IN'
export const ERROR_SIGN_IN = 'ERROR_SIGN_IN'
export const SUCCESS_SIGN_IN = 'SUCCESS_SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'


export const AWAITING_GET_USER = 'AWAITING_GET_USER'
export const ERROR_GET_USER = 'ERROR_GET_USER'
export const SUCCESS_GET_USER = 'SUCCESS_GET_USER'

export const AWAITING_GET_FEED = 'AWAITING_FEED';
export const SUCCESS_GET_FEED = 'GOT_FEED';
export const ERROR_GET_FEED = 'GOT_FEED';

export const SETTING_SIDEBAR = 'SETTING_SIDEBAR'

export const AWAITING_GET_ACCOUNT = 'AWAITING_GET_ACCOUNT'
export const ERROR_GET_ACCOUNT = 'ERROR_GET_ACCOUNT'
export const SUCCESS_GET_ACCOUNT = 'SUCCESS_GET_ACCOUNT'

export const AWAITING_GET_NOTIFICATIONS = 'AWAITING_GET_NOTIFICATIONS'
export const ERROR_GET_NOTIFICATIONS = 'ERROR_GET_NOTIFICATIONS'
export const SUCCESS_GET_NOTIFICATIONS = 'SUCCESS_GET_NOTIFICATIONS'


export const ERROR_DELETE_NOTIFICATIONS = 'ERROR_DELETE_NOTIFICATIONS'
export const SUCCESS_DELETE_NOTIFICATIONS = 'SUCCESS_DELETE_NOTIFICATIONS'

// Post Actions
export const AWAITING_GET_POSTS = 'AWAITING_GET_POSTS'
export const SUCCESS_GET_POSTS = 'SUCCESS_GET_POSTS';
export const ERROR_GET_POSTS = 'ERROR_GET_POSTS'


export const AWAITING_UPDATE_POST = 'AWAITING_UPDATE_POST'
export const SUCCESS_UPDATE_POST = 'SUCCESS_UPDATE_POST';
export const ERROR_UPDATE_POST = 'ERROR_UPDATE_POST'


export const AWAITING_DELETE_POST = 'AWAITING_DELETE_POST'
export const SUCCESS_DELETE_POST = 'SUCCESS_DELETE_POST';
export const ERROR_DELETE_POST = 'ERROR_DELETE_POST'

export const AWAITING_GET_POST_BY_ID = 'AWAITING_GET_POST_BY_ID'
export const SUCCESS_GET_POST_BY_ID = 'SUCCESS_GET_POST_BY_ID';
export const ERROR_GET_POST_BY_ID = 'ERROR_GET_POST_BY_ID'

export const AWAITING_GET_POSTS_RELATIONS = 'AWAITING_GET_POSTS_RELATIONS'
export const SUCCESS_GET_POSTS_RELATIONS = 'SUCCESS_GET_POSTS_RELATIONS';
export const ERROR_GET_POSTS_RELATIONS = 'ERROR_GET_POSTS_RELATIONS'

export const SUCCESS_UPVOTE_POSTS = 'SUCCESS_UPVOTE_POSTS';
export const ERROR_UPVOTE_POSTS = 'ERROR_UPVOTE_POSTS'

export const SUCCESS_FOLLOW_POST = 'SUCCESS_FOLLOW_POST';
export const ERROR_FOLLOW_POST = 'ERROR_FOLLOW_POST'

export const PUSH_POST_STACK = 'PUSH_POST_STACK';
export const POP_POST_STACK = 'POP_POST_STACK';
export const CLEAR_POST_STACK = 'CLEAR_POST_STACK';

//Project Actions
export const AWAITING_GET_PROJECTS = 'AWAITING_GET_PROJECTS'
export const SUCCESS_GET_PROJECTS = 'SUCCESS_GET_PROJECTS';
export const ERROR_GET_PROJECTS = 'ERROR_GET_PROJECTS'


export const AWAITING_GET_PROJECT_BY_ID = 'AWAITING_GET_PROJECT_BY_ID'
export const SUCCESS_GET_PROJECT_BY_ID = 'SUCCESS_GET_PROJECT_BY_ID';
export const ERROR_GET_PROJECT_BY_ID = 'ERROR_GET_PROJECT_BY_ID'


//Organization Actions
export const AWAITING_GET_ORGANIZATIONS = 'AWAITING_GET_ORGANIZATIONS'
export const SUCCESS_GET_ORGANIZATIONS = 'SUCCESS_GET_ORGANIZATIONS';
export const ERROR_GET_ORGANIZATIONS = 'ERROR_GET_ORGANIZATIONS'


export const AWAITING_GET_ORGANIZATION_BY_ID = 'AWAITING_GET_ORGANIZATION_BY_ID'
export const SUCCESS_GET_ORGANIZATION_BY_ID = 'SUCCESS_GET_ORGANIZATION_BY_ID';
export const ERROR_GET_ORGANIZATION_BY_ID = 'ERROR_GET_ORGANIZATION_BY_ID'

export const PUSH_SPACE = 'PUSH_SPACE';
export const POP_SPACE = 'POP_SPACE';
export const CLEAR_SPACE = 'CLEAR_SPACE';

export const SET_ORIGIN = 'SET_ORIGIN';
export const CLEAR_ORIGIN = 'CLEAR_ORIGIN';
