/**
 * @generated SignedSource<<9eb644861bd6813d588a6f13cb36ffe5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetLastRoomIdInput = {
  roomId: string;
  spaceId?: string | null;
};
export type MessageWindowRoomMutation$variables = {
  input: SetLastRoomIdInput;
};
export type MessageWindowRoomMutation$data = {
  readonly setLastRoomId: {
    readonly spaceUser: {
      readonly id: string;
      readonly lastRoomId: number | null;
    } | null;
  } | null;
};
export type MessageWindowRoomMutation = {
  variables: MessageWindowRoomMutation$variables;
  response: MessageWindowRoomMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetLastRoomIdReply",
    "kind": "LinkedField",
    "name": "setLastRoomId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SpaceUser",
        "kind": "LinkedField",
        "name": "spaceUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastRoomId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageWindowRoomMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageWindowRoomMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9913498d01ca7a40e543ec9a71c89966",
    "id": null,
    "metadata": {},
    "name": "MessageWindowRoomMutation",
    "operationKind": "mutation",
    "text": "mutation MessageWindowRoomMutation(\n  $input: SetLastRoomIdInput!\n) {\n  setLastRoomId(input: $input) {\n    spaceUser {\n      id\n      lastRoomId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a88ea814096bbe6b63e9821a11e5f5e6";

export default node;
