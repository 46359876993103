/**
 * @generated SignedSource<<152bcdd926c18a36e9432a38a377f894>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FavoriteSpaceInput = {
  spaceId: number;
  favorite: boolean;
};
export type SpaceFavoriteButtonMutation$variables = {
  input: FavoriteSpaceInput;
  connections: ReadonlyArray<string>;
};
export type SpaceFavoriteButtonMutation$data = {
  readonly toggleFavoriteSpace: {
    readonly deletedFavoriteId: string | null;
    readonly space: {
      readonly id: string;
      readonly favorite: boolean | null;
    } | null;
    readonly favoriteSpaceEdge: {
      readonly node: {
        readonly id: string;
        readonly space: {
          readonly spaceId: number;
          readonly " $fragmentSpreads": FragmentRefs<"SpaceListItemFragment">;
        } | null;
      } | null;
    } | null;
  } | null;
};
export type SpaceFavoriteButtonMutation = {
  variables: SpaceFavoriteButtonMutation$variables;
  response: SpaceFavoriteButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedFavoriteId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "favorite",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Space",
  "kind": "LinkedField",
  "name": "space",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "spaceId",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "connections",
    "variableName": "connections"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SpaceFavoriteButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "FavoriteSpaceOutput",
        "kind": "LinkedField",
        "name": "toggleFavoriteSpace",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FavoriteEdge",
            "kind": "LinkedField",
            "name": "favoriteSpaceEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Favorite",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Space",
                    "kind": "LinkedField",
                    "name": "space",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SpaceListItemFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SpaceFavoriteButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "FavoriteSpaceOutput",
        "kind": "LinkedField",
        "name": "toggleFavoriteSpace",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedFavoriteId",
            "handleArgs": (v8/*: any*/)
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FavoriteEdge",
            "kind": "LinkedField",
            "name": "favoriteSpaceEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Favorite",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Space",
                    "kind": "LinkedField",
                    "name": "space",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilepic",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "project",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectType",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SpaceUser",
                        "kind": "LinkedField",
                        "name": "currUser",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "spaceUserId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "accepted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "request",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "requestType",
                            "storageKey": null
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__id",
                                "storageKey": null
                              }
                            ]
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "favoriteSpaceEdge",
            "handleArgs": (v8/*: any*/)
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "73447de52dbd9117fe708e86e4a3eb7b",
    "id": null,
    "metadata": {},
    "name": "SpaceFavoriteButtonMutation",
    "operationKind": "mutation",
    "text": "mutation SpaceFavoriteButtonMutation(\n  $input: FavoriteSpaceInput!\n) {\n  toggleFavoriteSpace(input: $input) {\n    deletedFavoriteId\n    space {\n      id\n      favorite\n    }\n    favoriteSpaceEdge {\n      node {\n        id\n        space {\n          spaceId\n          ...SpaceListItemFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment JoinButtonFragment on Space {\n  spaceId\n  id\n  currUser {\n    id\n    spaceUserId\n    type\n    accepted\n    request\n    requestType\n  }\n}\n\nfragment SpaceFavoriteButtonFragment on Space {\n  favorite\n  spaceId\n}\n\nfragment SpaceIconFragment on Space {\n  profilepic\n  project\n  projectType\n}\n\nfragment SpaceListItemFragment on Space {\n  name\n  spaceId\n  ...SpaceIconFragment\n  ...SpacePreviewCardWrapperFragment\n  ...JoinButtonFragment\n  ...SpaceFavoriteButtonFragment\n}\n\nfragment SpacePreviewCardWrapperFragment on Space {\n  spaceId\n}\n"
  }
};
})();

(node as any).hash = "824f292730dca5b9780fd4ffc73b668e";

export default node;
