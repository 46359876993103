/**
 * @generated SignedSource<<3102bb85b2419428d357756455783058>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MessageButtonQuery$variables = {};
export type MessageButtonQuery$data = {
  readonly me: {
    readonly userId: number;
  } | null;
};
export type MessageButtonQuery = {
  variables: MessageButtonQuery$variables;
  response: MessageButtonQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MessageButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83c2cc1a6e2743a57013ba4fd89323b0",
    "id": null,
    "metadata": {},
    "name": "MessageButtonQuery",
    "operationKind": "query",
    "text": "query MessageButtonQuery {\n  me {\n    userId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef5be3e93a724abd4bc81951e2144a56";

export default node;
