/**
 * @generated SignedSource<<79ccc01c9fc0ac2c23334f7f5acd9bf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PinMessageInput = {
  messageId: string;
  pinned: boolean;
};
export type MessageCardPinMutation$variables = {
  input: PinMessageInput;
};
export type MessageCardPinMutation$data = {
  readonly pinMessage: {
    readonly message: {
      readonly id: string;
      readonly pinned: boolean | null;
    } | null;
  } | null;
};
export type MessageCardPinMutation = {
  variables: MessageCardPinMutation$variables;
  response: MessageCardPinMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MessageUpdateReply",
    "kind": "LinkedField",
    "name": "pinMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Message",
        "kind": "LinkedField",
        "name": "message",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pinned",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageCardPinMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageCardPinMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3460557f85f00de8397cf1464b28c792",
    "id": null,
    "metadata": {},
    "name": "MessageCardPinMutation",
    "operationKind": "mutation",
    "text": "mutation MessageCardPinMutation(\n  $input: PinMessageInput!\n) {\n  pinMessage(input: $input) {\n    message {\n      id\n      pinned\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "08f2ee8c40d337856c78f17323e7f1a5";

export default node;
