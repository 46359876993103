/**
 * @generated SignedSource<<67470a0b417a0f5228e6786587e39cf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChannelOrderChange = {
  spaceId?: number | null;
  roomId?: number | null;
  oldIndex?: number | null;
  newIndex?: number | null;
};
export type ReorderChannelsMutation$variables = {
  input: ChannelOrderChange;
};
export type ReorderChannelsMutation$data = {
  readonly reorderSpaceChannels: {
    readonly id: string;
    readonly index: number | null;
  } | null;
};
export type ReorderChannelsMutation = {
  variables: ReorderChannelsMutation$variables;
  response: ReorderChannelsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Room",
    "kind": "LinkedField",
    "name": "reorderSpaceChannels",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "index",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReorderChannelsMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReorderChannelsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4351071b90f62031abd1fdbddd04a826",
    "id": null,
    "metadata": {},
    "name": "ReorderChannelsMutation",
    "operationKind": "mutation",
    "text": "mutation ReorderChannelsMutation(\n  $input: ChannelOrderChange!\n) {\n  reorderSpaceChannels(input: $input) {\n    id\n    index\n  }\n}\n"
  }
};
})();

(node as any).hash = "8e0c0d292cf02c5cbcb1efcce3ba9d0f";

export default node;
