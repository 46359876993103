/**
 * @generated SignedSource<<010ab7f36f8c4399cbd37b3ed7a4163d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReactionChipQuery$variables = {};
export type ReactionChipQuery$data = {
  readonly me: {
    readonly userId: number;
  } | null;
};
export type ReactionChipQuery = {
  variables: ReactionChipQuery$variables;
  response: ReactionChipQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReactionChipQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ReactionChipQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7896b361f9ea131227369080456ba14c",
    "id": null,
    "metadata": {},
    "name": "ReactionChipQuery",
    "operationKind": "query",
    "text": "query ReactionChipQuery {\n  me {\n    userId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "59957392acb9994e5d48468799161b8b";

export default node;
