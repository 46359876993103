/**
 * @generated SignedSource<<24872687ae472869621590b7efaff9b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SpaceFavoriteButtonFragment$data = {
  readonly favorite: boolean | null;
  readonly spaceId: number;
  readonly " $fragmentType": "SpaceFavoriteButtonFragment";
};
export type SpaceFavoriteButtonFragment$key = {
  readonly " $data"?: SpaceFavoriteButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpaceFavoriteButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpaceFavoriteButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spaceId",
      "storageKey": null
    }
  ],
  "type": "Space",
  "abstractKey": null
};

(node as any).hash = "799d23d04d1c5923fab0b2dcf551df7a";

export default node;
