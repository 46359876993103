/**
 * @generated SignedSource<<186738c475f8a8f70537c31493e467ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnreadChipFragment$data = {
  readonly currUser: {
    readonly unreadNum: number | null;
  } | null;
  readonly " $fragmentType": "UnreadChipFragment";
};
export type UnreadChipFragment$key = {
  readonly " $data"?: UnreadChipFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnreadChipFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnreadChipFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RoomUser",
      "kind": "LinkedField",
      "name": "currUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadNum",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Room",
  "abstractKey": null
};

(node as any).hash = "8f8ffed2519631c35944908995960944";

export default node;
