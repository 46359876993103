/**
 * @generated SignedSource<<7b9f52296304fde5c5968fcc287c010c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteMessageInput = {
  messageId: string;
};
export type CommentsAddOnDeleteMutation$variables = {
  input: DeleteMessageInput;
};
export type CommentsAddOnDeleteMutation$data = {
  readonly deleteMessage: {
    readonly deletedMessageId: string | null;
    readonly roomId: string | null;
    readonly message: {
      readonly id: string;
      readonly deleted: boolean | null;
    } | null;
  } | null;
};
export type CommentsAddOnDeleteMutation = {
  variables: CommentsAddOnDeleteMutation$variables;
  response: CommentsAddOnDeleteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MessageDeleteEdge",
    "kind": "LinkedField",
    "name": "deleteMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedMessageId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roomId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Message",
        "kind": "LinkedField",
        "name": "message",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentsAddOnDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommentsAddOnDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "786bdbfa025e723acc529b4feb4b1885",
    "id": null,
    "metadata": {},
    "name": "CommentsAddOnDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation CommentsAddOnDeleteMutation(\n  $input: DeleteMessageInput!\n) {\n  deleteMessage(input: $input) {\n    deletedMessageId\n    roomId\n    message {\n      id\n      deleted\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f9e7d1fb2f38ae2dcd4a563515bdead0";

export default node;
