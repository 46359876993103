/**
 * @generated SignedSource<<2e678546fa252033a86785ebf922f9cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileOverlayQuery$variables = {};
export type ProfileOverlayQuery$data = {
  readonly me: {
    readonly userId: number;
    readonly firstname: string | null;
    readonly lastname: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"UserIconFragment" | "DisplayOptionsFragment">;
  } | null;
};
export type ProfileOverlayQuery = {
  variables: ProfileOverlayQuery$variables;
  response: ProfileOverlayQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileOverlayQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserIconFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DisplayOptionsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileOverlayQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profilepic",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "darkMode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productivityView",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d79c5a0d94bb291958afbf6ea0fc89ba",
    "id": null,
    "metadata": {},
    "name": "ProfileOverlayQuery",
    "operationKind": "query",
    "text": "query ProfileOverlayQuery {\n  me {\n    userId\n    firstname\n    lastname\n    ...UserIconFragment\n    ...DisplayOptionsFragment\n    id\n  }\n}\n\nfragment DisplayOptionsFragment on User {\n  darkMode\n  productivityView\n}\n\nfragment UserIconFragment on User {\n  userId\n  firstname\n  lastname\n  profilepic\n}\n"
  }
};
})();

(node as any).hash = "4204412861cf4f35e24d2349fa3c9ffa";

export default node;
