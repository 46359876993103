/**
 * @generated SignedSource<<aeb54683186cd769e95d1e740deea761>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMessageButtonFragment_me$data = {
  readonly userId: number;
  readonly " $fragmentType": "UserMessageButtonFragment_me";
};
export type UserMessageButtonFragment_me$key = {
  readonly " $data"?: UserMessageButtonFragment_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMessageButtonFragment_me">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMessageButtonFragment_me",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f5e0a205eab5b0048b9541c9c44704a6";

export default node;
