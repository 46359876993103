/**
 * @generated SignedSource<<1cdd408a6b8d6189ef199adb1925bbec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProfileOverlaySignOutMutation$variables = {};
export type ProfileOverlaySignOutMutation$data = {
  readonly signOut: {
    readonly userId: number;
  } | null;
};
export type ProfileOverlaySignOutMutation = {
  variables: ProfileOverlaySignOutMutation$variables;
  response: ProfileOverlaySignOutMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileOverlaySignOutMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "signOut",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileOverlaySignOutMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "signOut",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "882039e2aa6d2c37f718607b61d42ba6",
    "id": null,
    "metadata": {},
    "name": "ProfileOverlaySignOutMutation",
    "operationKind": "mutation",
    "text": "mutation ProfileOverlaySignOutMutation {\n  signOut {\n    userId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "92e751b04a84263b9ff7ec9c2bfdcfa8";

export default node;
