/**
 * @generated SignedSource<<0cf56c0a67cd4d1388c9aeeafb6becc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChannelList_rooms$data = {
  readonly __id: string;
  readonly id: string;
  readonly spaceId: number;
  readonly currUser: {
    readonly spaceUserId: string | null;
    readonly type: string | null;
  };
  readonly rooms: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly roomId: number | null;
        readonly spaceId: number | null;
        readonly name: string | null;
        readonly id: string;
        readonly currUser: {
          readonly id: string;
          readonly roomUserId: number | null;
          readonly unread: boolean | null;
          readonly unreadNum: number | null;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"ChannelListItem" | "RoomSettings_fragment">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "ChannelList_rooms";
};
export type ChannelList_rooms$key = {
  readonly " $data"?: ChannelList_rooms$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChannelList_rooms">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "rooms"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "spaceId",
  "storageKey": null
},
v3 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "roomCount"
    },
    {
      "kind": "RootArgument",
      "name": "roomCursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "roomCount",
        "cursor": "roomCursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "roomCount",
          "cursor": "roomCursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ChannelListPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ChannelList_rooms",
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SpaceUser",
      "kind": "LinkedField",
      "name": "currUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "spaceUserId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "rooms",
      "args": null,
      "concreteType": "RoomConnection",
      "kind": "LinkedField",
      "name": "__ChannelList_rooms_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoomEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Room",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ChannelListItem"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RoomSettings_fragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "roomId",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RoomUser",
                  "kind": "LinkedField",
                  "name": "currUser",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "roomUserId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unread",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unreadNum",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "type": "Space",
  "abstractKey": null
};
})();

(node as any).hash = "be8cab1e30cbfb842117f6f603aa1ce3";

export default node;
