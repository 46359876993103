/**
 * @generated SignedSource<<8712f8e3cc9dfe44d93abb616a06f1b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RoomSettings_fragment$data = {
  readonly name: string | null;
  readonly id: string;
  readonly roomId: number | null;
  readonly description: string | null;
  readonly type: string | null;
  readonly visibility: string | null;
  readonly currUser: {
    readonly id: string;
    readonly roomUserId: number | null;
    readonly unread: boolean | null;
    readonly unreadNum: number | null;
  } | null;
  readonly " $fragmentType": "RoomSettings_fragment";
};
export type RoomSettings_fragment$key = {
  readonly " $data"?: RoomSettings_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RoomSettings_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoomSettings_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roomId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoomUser",
      "kind": "LinkedField",
      "name": "currUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roomUserId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unread",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadNum",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Room",
  "abstractKey": null
};
})();

(node as any).hash = "f8bd12e229fc0f04ea7f5bdc93e2d6de";

export default node;
