/**
 * @generated SignedSource<<0f12633ee28317eef3f3135d5a265ece>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MainMenuQuery$variables = {};
export type MainMenuQuery$data = {
  readonly me: {
    readonly id: string;
    readonly userId: number;
    readonly firstname: string | null;
    readonly unreadMessages: boolean | null;
    readonly unreadMessagesNum: number | null;
    readonly notificationsNum: number | null;
    readonly darkMode: boolean | null;
    readonly " $fragmentSpreads": FragmentRefs<"UserIconFragment">;
  } | null;
};
export type MainMenuQuery = {
  variables: MainMenuQuery$variables;
  response: MainMenuQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unreadMessages",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unreadMessagesNum",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "read",
      "value": false
    }
  ],
  "kind": "ScalarField",
  "name": "notificationsNum",
  "storageKey": "notificationsNum(read:false)"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "darkMode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MainMenuQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserIconFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MainMenuQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profilepic",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3a4fd7f38b4077cfe1cd81728a8e4796",
    "id": null,
    "metadata": {},
    "name": "MainMenuQuery",
    "operationKind": "query",
    "text": "query MainMenuQuery {\n  me {\n    id\n    userId\n    firstname\n    unreadMessages\n    unreadMessagesNum\n    notificationsNum(read: false)\n    darkMode\n    ...UserIconFragment\n  }\n}\n\nfragment UserIconFragment on User {\n  userId\n  firstname\n  lastname\n  profilepic\n}\n"
  }
};
})();

(node as any).hash = "b6ff2e000be6ef1ecc8d5376f50b3b22";

export default node;
