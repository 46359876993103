/**
 * @generated SignedSource<<6b8460728555d383f395d18458f31224>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SpaceColumnQuery$variables = {
  id: number;
  roomCount?: number | null;
  roomCursor?: string | null;
};
export type SpaceColumnQuery$data = {
  readonly space: {
    readonly numRooms: number | null;
    readonly currUser: {
      readonly lastRoomId: number | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ServerOptions_space" | "ChannelList_rooms">;
  } | null;
};
export type SpaceColumnQuery = {
  variables: SpaceColumnQuery$variables;
  response: SpaceColumnQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roomCount"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roomCursor"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "spaceId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numRooms",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastRoomId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "spaceId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilepic",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "roomCursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "roomCount"
  }
],
v10 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SpaceColumnQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Space",
        "kind": "LinkedField",
        "name": "space",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ServerOptions_space"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChannelList_rooms"
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SpaceUser",
            "kind": "LinkedField",
            "name": "currUser",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SpaceColumnQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Space",
        "kind": "LinkedField",
        "name": "space",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "project",
            "storageKey": null
          },
          (v8/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SpaceUser",
            "kind": "LinkedField",
            "name": "currUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spaceUserId",
                "storageKey": null
              },
              (v7/*: any*/),
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "RoomConnection",
            "kind": "LinkedField",
            "name": "rooms",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RoomEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Room",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "roomId",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dm",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "otherUser",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "userId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstname",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastname",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoomUser",
                        "kind": "LinkedField",
                        "name": "currUser",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unreadNum",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "roomUserId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unread",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "visibility",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "ChannelList_rooms",
            "kind": "LinkedHandle",
            "name": "rooms"
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ccfec5bde363d4d300891b527cf72eca",
    "id": null,
    "metadata": {},
    "name": "SpaceColumnQuery",
    "operationKind": "query",
    "text": "query SpaceColumnQuery(\n  $id: Int!\n  $roomCount: Int\n  $roomCursor: String\n) {\n  space(spaceId: $id) {\n    ...ServerOptions_space\n    ...ChannelList_rooms\n    numRooms\n    currUser {\n      lastRoomId\n      id\n    }\n    id\n  }\n}\n\nfragment ChannelListItem on Room {\n  name\n  roomId\n  type\n  dm\n  spaceId\n  ...RoomIcon\n  ...UnreadChipFragment\n  currUser {\n    id\n    roomUserId\n    unread\n    unreadNum\n  }\n}\n\nfragment ChannelList_rooms on Space {\n  id\n  spaceId\n  currUser {\n    spaceUserId\n    type\n    id\n  }\n  rooms(first: $roomCount, after: $roomCursor) {\n    edges {\n      node {\n        ...ChannelListItem\n        ...RoomSettings_fragment\n        roomId\n        spaceId\n        name\n        id\n        currUser {\n          id\n          roomUserId\n          unread\n          unreadNum\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment RoomIcon on Room {\n  type\n  dm\n  spaceId\n  otherUser {\n    userId\n    firstname\n    lastname\n    id\n  }\n  profilepic\n}\n\nfragment RoomSettings_fragment on Room {\n  name\n  id\n  roomId\n  description\n  type\n  visibility\n  currUser {\n    id\n    roomUserId\n    unread\n    unreadNum\n  }\n}\n\nfragment ServerOptions_space on Space {\n  id\n  spaceId\n  name\n  type\n  project\n  profilepic\n  numRooms\n}\n\nfragment UnreadChipFragment on Room {\n  currUser {\n    unreadNum\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ca7fb7ddb319f087ecbc820e820077ff";

export default node;
