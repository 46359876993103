/**
 * @generated SignedSource<<380892aef88f5613f52758db7e831d23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RoomUsersListQuery$variables = {};
export type RoomUsersListQuery$data = {
  readonly me: {
    readonly userId: number;
  } | null;
};
export type RoomUsersListQuery = {
  variables: RoomUsersListQuery$variables;
  response: RoomUsersListQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoomUsersListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RoomUsersListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b90e0d89e55a01b422abb9e6b762751",
    "id": null,
    "metadata": {},
    "name": "RoomUsersListQuery",
    "operationKind": "query",
    "text": "query RoomUsersListQuery {\n  me {\n    userId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "65c8d6b4c65795ca9da7559d59adf6bd";

export default node;
